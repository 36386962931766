import {
    mdiAccount,
    mdiInvoiceExportOutline,
    mdiInvoiceImport,
    mdiInvoiceListOutline,
    mdiSpray,
    mdiViewDashboardVariant
} from "@mdi/js";

const menu = [
    {
        title: 'Dashboard',
        href: '/dashboard',
        icon: mdiViewDashboardVariant,
        usergroup: 'user'
    },
    {
        title: 'Products',
        href: '/product',
        icon: mdiSpray,
        usergroup: 'user'
    },
    {
        title: 'Users',
        href: '/user',
        icon: mdiAccount,
        usergroup: 'admin'
    },
    {
        title: 'Orders',
        href: '/order',
        icon: mdiInvoiceListOutline,
        usergroup: 'user'
    },
    {
        title: 'Import',
        href: '/import',
        icon: mdiInvoiceImport,
        usergroup: 'user'
    },
    {
        title: 'Export',
        href: '/export',
        icon: mdiInvoiceExportOutline,
        usergroup: 'user'
    },

];
export default menu;
